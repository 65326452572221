.scan-container {
    display: flex;
    flex-direction: row;
}

.scan-col{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 20px;
    min-height: 850px;
    min-width: 650px;
    max-width: 850px;
    margin-top: 60px;
}

.employee-header {
    font-size: 3rem;
    width: 100%;
    font-weight: bold;
    height: 60px;
    color: #003764;
}

.status-select{
    width: 30%;
}

.text-center {
    text-align: center;
}

.scan-info-container{
    margin-top: 40px;
    padding-top: 40px;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    min-width: 780px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.scan-info-row-1 {
    height: 50%;
    padding-left: 20px;
}
.scan-info-row-2 {
    padding-top: 40px;
    height: 50%;
    width: 100%;
}

.scan-title{
    font-size: 3rem;
    width: 100%;
    font-weight: bold;
    color: #003764;
}

.margin-bottom {
    margin-bottom: 100px;
}

.white {
    color: white;
    font-size: 3rem;
}

.scan-input{
    font-size: 35px;
    width: 350px;
    font-weight: bold;
    color: #003764;
    display: inline;
}

.scanner-result {
    margin-left: 0px;
}

.already-scanned {
    background-color:  red;
}

.not-scanned {
    background-color: green;
}

.not-found {
    color: red;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

.right {
    margin-right: 10px;
}

.scan-count {
    font-size: 12rem;
    margin-top: 120px;
    font-weight: bold;
    color: #003764;
}


