/* Variables */
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;

/* Styles */
body {
  font: 14px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  color: #4d4d4d;
  width: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 300;
}

td {
	padding: 12px;
}

.savings { color: $vin-green; }
.loss { color: $vin-red; }
input.small { width: 46px; }
td.fuel-savings-label { width: 175px; }

.error-message {
  color: red;
}

.navbar-nav span[role=button] {
  padding: 15px;
  display: inline-block;
  line-height: 20px;
}

// Navigation
.nav-navlink {
  position: relative;
  &> a.no-active {
    background: #163762 !important;
  }
  &:hover {
    .nav-submenu {
      display: flex;
    }
    .nav-submenu .law-tag{
      margin-left: 15px;
    }
    .nav-submenu .sku{
      margin-left: 10px;
    }
    .nav-submenu .setting{
      margin-left: 12px;
    }
  }
  .nav-submenu {
    display: none;
    flex-wrap: wrap;
    position: absolute;
    top: 100%;
    background-color: #1d4171;
    min-width: 170px;
    width: 100%;
    z-index: 1000;
    border-left: 2px solid #163762;
    border-right: 2px solid #163762;
    border-top: 2px solid #163762;
    .nav-subnavlink {
      display: block;
      width: 100%;
      border-bottom: 2px solid #163762;
      font-weight: 500;
      &.active {
        background-color: #2994c2;
      }
    }
  }
}

// Choose a file - Custom Button
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: #fff;
  background-color: #003764;
  display: inline-block;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px 30px;
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: #2994c2;
}

.inputfile + label {
  cursor: pointer; /* "hand" cursor */
}

.inputfile:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

// tables

table {
  thead {
    tr {
      th {
        border: 2px solid #d2d2d2;
      }
      th > span,
      th > div {
        font-weight: bold;
        color: #000;
        text-transform: uppercase;
      }
    }
  }
  tbody {
    tr {
      td {
        border: 2px solid #e9e9e9;
      }
    }
  }
}

div[class^='MuiPaper-root-'],
div[class*=' MuiPaper-root-'] {
  box-shadow: none;
}

div[class^='MuiToolbar-root-'][role="toolbar"],
div[class*=' MuiToolbar-root-'][role="toolbar"] {
  box-shadow: none;
  border: 2px solid #d2d2d2;
  border-bottom: none;
  padding-top: 15px;
}

table tfoot[class^='MuiTableFooter-root'] {
  box-shadow: none;
  border: 2px solid #d2d2d2;
  border-top: none;
}

.content-editable {
  padding: 1rem;
}

.content-editable:hover {
  background: #f7f7f7;
}

.content-editable:focus {
  background: #fcf8e1;
  outline: 0;
}