.copyBom-layers-form {
  margin: 50px 50px 50px 0 !important;
}
.copyBom-layer-inputs {
  display: flex;
  gap: 5rem;
  margin: 20px;
}
#copyBom-raw-mat-type-input {
  min-width: 200px;
  margin-right: 15px;
}
#copyBom-autocomplete-bottom-panel {
  width: 300px !important;
}

.copyBom-convoluted-box {
  margin-top: 20px;
}
.copyBom-inputs-warning {
  color: red;
  font-weight: bold !important;
  text-transform: uppercase;
}
.copyBom-layer-btn-box {
  display: flex;
  gap: 1rem;
  margin: 20px;
}
.copyBom-cancel-layer-btn {
  display: block !important;
}
.copyBom-add-layer-btn {
  display: block !important;
  color: white !important;
  background: rgb(30, 66, 113) !important;
}
@media only screen and (max-width: 1000px) {
  .copyBom-layer-inputs {
    flex-direction: column;
  }
}