.newLayerForm {
  margin: 50px 50px 50px 0;
}
.layer-inputs {
  display: flex;
  gap: 5rem;
  margin: 20px;
}
#raw-mat-type-input {
  min-width: 200px;
  margin-right: 15px;
}
#autocomplete-bottom-panel {
  width: 300px !important;
}

.convoluted-box {
  margin-top: 20px;
}
.inputs-warning {
  color: red;
  font-weight: bold !important;
  text-transform: uppercase;
}
.layer-btn-box {
  display: flex;
  gap: 1rem;
  margin: 20px;
}
.cancel-layer-btn {
  display: block !important;
}
.add-layer-btn {
  display: block !important;
  color: white !important;
  background: rgb(30, 66, 113) !important;
}
@media only screen and (max-width: 1000px) {
  .layer-inputs {
    flex-direction: column;
  }
}