.layers-container {
  padding: 2px;
  margin-left: 20px;
  margin-top: 20px;
}
#font-awesome-icon-remove {
  font-size: 1.50rem;
  cursor: pointer;
  display: inline;
}
#layer-material {
  margin-left: 20px;
  font-size: 20px
}
#layer-part {
  font-size: 20px;
}
#layer-topper {
  margin-left: 15px;
  font-size: 15px
}
#layer-convoluted {
  margin-left: 15px;
  font-size: 15px
}
#layer-depth_in {
  font-size: 20px;
  float: right;
}