.copyBom-draggable-box {
  margin: 15px 0;
}
.copyBom-layers-container {
  padding: 2px;
  margin-left: 20px;
  margin-top: 20px;
}
#copyBom-font-awesome-icon-remove {
  font-size: 1.50rem;
  cursor: pointer;
  display: inline;
}
#copyBom-layer-material {
  margin-left: 20px;
  font-size: 20px
}
#copyBom-layer-part {
  font-size: 20px;
}
#copyBom-layer-topper {
  margin-left: 15px;
  font-size: 15px
}
#copyBom-layer-convoluted {
  margin-left: 15px;
  font-size: 15px
}
#copyBom-layer-depth_in {
  font-size: 20px;
  float: right;
}