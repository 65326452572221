.retail-table table th{ 
    color: black !important;

}
.MuiTableCell-paddingCheckbox {
    min-width: 100px;
}
.dataTable_row{
    padding-top: 25px;
}
.PrivateTabIndicator-colorSecondary-4 {
    background-color: white !important;
}
