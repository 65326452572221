.tabGrid {
  margin-left: -25px !important;
  margin-bottom: -25px !important;
}
.tab_box {
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border: 2px solid #003764 !important;
  min-height: 38px !important;
}
.tab_container {
  margin-bottom: -34px;
}
.forecast_button {
  /* background-color: #003764 !important; */
  color: white !important;
  background-color: #2894c3 !important;
  font-size: 16px !important;
  border: 1px solid #003764 !important;
}
.forecast_button_container {
  margin-top: 20px;
  margin-bottom: 20px;
  /* margin-left: 15px; */
}
.curve-header {
  margin-top: 40px;
  margin-left: 45px;
}
.curve_button_container {
  margin-top: 20px;
}

.campaign_sub_container {
  margin-left: -25px;
  min-width: 855px;
}
.campaign_auto .MuiInputBase-input {
  height: 15px;
}

.modal-title {
  background-color: #47c9a2;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 75px;
}
.modal-typo-title {
  text-align: center;
}
.modal-description {
  margin: 15px;
  text-align: center;
  margin-top: 30px;
}
.modal-typo-description {
  font-weight: bold !important;
  font-size: 18px !important;
  padding-right: 15px;
}
.contained-button-file {
  margin-left: 10px;
}
.forecast_button_archive_template {
  border: 1px solid #003764 !important;
  background-color: #ffcc00 !important;

  /* color: white !important; */
}
.forecast_button_archive {
  background-color: #ff671d !important;
  border: 1px solid #003764 !important;

  /* color: white !important; */
}
.PrivateTabIndicator-colorPrimary-5 {
  background-color: #ff671d !important;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #163762 !important;
  font-size: 18px;
}
.forecast-campaign-container {
  margin-top: 25px;
}
.campaign-container {
  margin-left: -25px;
  margin-right: -25px;
}
.forecast_button_container {
  border-radius: 5px !important;
  border: solid 2px #003764;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 50px;
}

.paper_curve_gird_container {
  border-radius: 5px !important;
  border: solid 2px #003764;
  margin-top: 25px;
}
.curve_gird_container {
  border-radius: 5px !important;
  border: solid 2px #003764;
  margin-top: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
}
body {
  height: 100vh;
}
.forecast-page-box {
  width: 100%;
  border-radius: 5px !important;
  border: solid 2px #003764;
}
