.progress-bar{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 135px;
}
.table-container{
    margin-top: 50px;
}
.MuiTypography-h6 {
    font-size: 1.5rem !important;
}
.sku-modal-title{
    /* background-color: #3FAFB5 !important;÷ */
    padding-top: 15px;
    padding-bottom: 15px;
    height: 75px;
  }
  .modal-typo-title{
    text-align: center;
  }
  .modal-description{
    margin: 15px;
    text-align: center;
    margin-top: 30px;
  }
  .modal-typo-description{
  font-weight: bold !important;
  font-size: 18px !important;
  padding-right: 15px;
  
  }