.sku-form{
    display: flex;
    flex-direction: row;
}

.sku-col{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 20px;
    min-height: 850px;
    min-width: 650px;
    max-width: 850px;
}
.sku-form-control{
    margin-left: 15px !important;
}

.chips{
    display: flex !important;
    flex-wrap: wrap !important;
}
.chip{
    margin: 2px !important;
}

.table-chip-create{
    margin-right: 15px !important;
    margin-bottom: 2px !important;
    margin-top: 2px !important;
    background-color: #787878 !important;
    color: white !important;
}
.table-chip-no-create{
    margin-right: 15px !important;
    margin-bottom: 2px !important;
    margin-top: 2px !important;
    background-color: #E8E8E8 !important;
    color: white !important;
}

.sku-form-select{
    margin-top: 10px !important;
    margin-left: 20px !important;
    margin-bottom: 15px !important;
}

.sku-form-select-inline{
    margin-left: 40% !important;
}
.sku-select{
    min-width: 175px;
}

.sku-select-quilt{
    width: 190px !important;
}

.sku-input{
    margin-left: 20px !important;
    margin-top: 10px !important;
    width: 175px !important;
}
.sku-auto-complete{
    margin-left: 20px !important;
    margin-top: 10px !important;
    width: 190px !important;
}
.sku-height{
    margin-left: 20px !important;
    margin-top: 10px !important;
    width: 130px !important;
    margin-right: 20px !important;
}
.sku-tape{
    margin-left: 30px !important;
    margin-top: 10px !important;
    width: 120px !important;
}
.sku-switch{
    margin-left: 50px !important;
    margin-top: 20px !important;
}

.sku-info{
    border-style: solid;
    border-width: .05rem;
}

.sku-lawtag{
    border-style: solid;
    border-width: .05rem;
    height: 100%;
}

.sku-flex-row{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}
.sku-table h4{
    min-width: 175px;
}
.sku-table,
.sku-build-label,
.sku-build{
    margin-left: 10px;
    margin-right: 20px;
}
.sku-border{
    border-style: solid;
    border-width: .05rem;
    height: 100%;
}
.sku-submit{
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-right: 30px;
    margin-bottom: 25px;
    float: right;
}
.sku-icon-button{
    float: right;
}

tr.no-hover:nth-child(even):hover {
    background-color: #f2f2f2 !important;
}

tr.no-hover:nth-child(odd):hover {
    background-color: transparent !important;
}


.overlay-container{
    position: absolute;
    top: 0;
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.input-overlay{
    padding-left: 60px;
    height: auto;
    width: auto;
    min-width: 600px;
    background: white;
    border: 1px solid black;
    z-index: 20;
    max-height: 900px;
    overflow-y: scroll;
}

.input-overlay-inputs{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 20px;
    font-weight: bold;
}

.blur {
    filter: blur(8px);
    pointer-events: none;
}

.grn-num{
    color: limegreen !important;
    font-size: 22px !important;
}

.red-num{
    color: red !important;
    font-size: 18px !important;
}

