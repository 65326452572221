.main {
  max-width: 100%;
  width: 100%;
}

.header {
  width: 100%;
}

.pull-right {
  float: right;
}

.body-left {
  display: flex;
  position: relative;
  height: 100%;
  float: left;
}

.body-main {
  display: flex;
  justify-content: center;
  font-size: 13px;
  line-height: 20px;
  color: #222;
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
}

.body-content {
  display: block;
  box-sizing: border-box;
  width: 100%;
  /* max-width: 1600px; */
  height: 100vw;
}

.baseContainer,
.base-container {
  width: 100%;
  height: 100%;
}

.report-status {
  padding-left: 25px;
}
.report-refresh {
  padding-left: 25px;
}
.report-status label {
  padding-right: 10px;
  padding-top: 10px;
}

.report-status-list {
  min-width: 250px;
  padding-top: 15px;
}

.inline-form-menu {
  width: 100%;
  text-align: right;
  background-color: #3f51b5;
  margin-top: 25pt;
}
