.table_cell {
  background-color: red !important;
  font-size: 26px !important;
}
.table_cell_passed {
  background-color: rgb(50, 205, 50) !important;
  font-size: 26px !important;
  color: black !important;
}
.tableRow_data {
  font-size: 22px !important;
  font-weight: bold !important;
}
.truck_table_cell {
  background-color: rgb(50, 205, 50) !important;
  font-size: 24px !important;
  color: black !important;
}
.truck_data {
  font-size: 18px !important;
  font-weight: bold !important;
}
