.skuqueue-prioritynote {
    &.skuqueue-prioritynote-normal,
    &.skuqueue-prioritynote-palletizepo {
        color: #005d00;
    }

    &.skuqueue-prioritynote-csurgent,
    &.skuqueue-prioritynote-retailover3days {
        color: #d4974a;
    }

    &.skuqueue-prioritynote-urgent {
        color: #b90000;
    }

    .skuqueue-prioritynote-icon {
        font-size: 1.25em;
        vertical-align: top;
    }
}

.skuqueue-confirmbutton {
    margin-bottom: 15px;
    
    &.animated {
        .fa-sync-alt {
            animation: rotating 2s linear infinite;
        }
    }
}

.skuqueue-form-control,
.skuqueue-form-control select {
    width: 150px;
}

@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}