body {
  padding: 0 !important;
  overflow: visible !important;
}
.viewCoreListTable {
    margin: 15px auto 25px auto;
}
.coreName {
    color: black;
}
.parentCoreTitle {
    color: #163762;
    font-weight: 500;
    font-family: inherit;
}
table th {
    background-color: #163762;
}
table {
  font-family: inherit;
}
.parentCoreContainer {
  display: flex;
  gap: 1rem;
  margin: 15px 0;
}
.viewCoreList {
  height: 100vh;
  
}
