.modal-title {
  background-color: #47c9a2;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 75px;
}
.modal-typo-title {
  text-align: center;
}
.modal-description {
  margin: 15px;
  text-align: center;
  margin-top: 30px;
}
.modal-typo-description {
  font-weight: bold !important;
  font-size: 18px !important;
  padding-right: 15px;
}
