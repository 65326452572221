.side-text{
    text-orientation: mixed;
    float: right;
}

.paper-layer{
    background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);
    border: 0;
    border-radius: 3;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);
    color: white;
    height: 15pt;
    padding: 0 30px;
    min-height: 35pt;
}

.paper-side-panel{
    display: block;
    min-height: 52px;
    padding-left: 2px;
    width: 15px;
    float: left;
}

.paper-top-panel{
    display: block;
    float: right;
}
.baseContainer-coreForm {
  padding: 1rem;
  height: 100vh;
}
.MuiTypography-h3 {
  font-family: inherit;
  color: #163762;
}

/*
'1', 'Bun'
'2', 'Coil'
'3', 'Topper'
'4', 'Talalay'
'5', 'LCore'
'6', 'LTopper'
'7', 'Unit'
*/