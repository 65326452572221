.shipped-orders-search-container {
  display: flex;
  margin: 2rem 0;

  .MuiFormControl-root {
    min-width: 10rem;
    margin-right: 4rem;
  }
}

.awaiting-shipment-table {
  margin-top: 2rem;

  .MuiButtonBase-root.MuiIconButton-root{
    margin-right: 1rem;
  }

  .MuiTableCell-root.MuiTableCell-body {
    padding: 0;

    .custom-table-cell {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      background-color: none;
      transition: background-color .5s ease;

      &.has-click {
        cursor: pointer;
      }

      &:active {
        background-color: rgba(23,55,98,0.25);
        transition: background-color 0s;
      }

      .MuiSvgIcon-root.hover-icon-copy {
        visibility: hidden;
        font-size: 1.25rem;
        color: rgba(0, 0, 0, 0.54);
      }

      &:hover, &:focus {
        .MuiSvgIcon-root.hover-icon-copy {
          visibility: visible;
        }
      }
    }
  }    
}

