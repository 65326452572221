.production_scan_grid_1 {
  background-color: #c0c0c0;
  /* margin: 15px; */
}
.main_nav_sublink {
  color: #003764 !important;
  font-size: 20px !important;
  font-weight: bold;
}
.main_nav_sublink:hover {
  color: #ff671d !important;
  font-style: italic;
}

.main_nav_typography {
  width: 100%;
  border-radius: 25px;
  text-align: center !important;
  color: #003764 !important;
  font-size: 24px !important;
  font-weight: bold !important;
}
.MuiAccordion-root:before {
  position: relative !important;
}
.grid_links {
  margin-bottom: 15px !important;
}
.grid_accordion {
  width: 100%;
  border-radius: 25px !important;
  border: solid 3px #ff671d;
  text-align: center;
  z-index: 1;
  margin-bottom: 10px;
  margin-top: 10px;
}
.grid_accordion:hover {
  border-radius: 25px !important;
  border: solid 3px #003764;
}
.main_nav_container {
  padding-bottom: 150px;
}
.production_scan_grid_1 {
  border: solid 2px white;
  justify-content: center;
  display: flex;
  text-align: center;
}
.production_typography {
  font-size: 24px !important;
  font-weight: bold !important;
}
.production_font_icon_sync {
  background-color: #b4d1ed;
  padding: 5px;
  border-radius: 50px;
}
.MuiFilledInput-root,
.sku_textfield {
  background-color: transparent !important;
  font-size: 20px !important;
}
.MuiFilledInput-root:focus,
.sku_textfield:focus-within {
  background-color: yellow !important;
}
.serial_number-sku_textfield {
  font-size: 35px !important;
  padding-left: 5px !important;
  font-weight: 700 !important;
}
.production_body {
  height: 120vh;
  /* border: 2px solid rgb(162, 160, 160); */
  /* padding-bottom: 225px; */
}
.scanned_typography {
  color: gray;
}
.paper_container {
  /* min-height: 75vh; */
  height: 100vh;
  box-shadow: none !important;
}
.connect-header {
  color: #163762 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  cursor: pointer;
}
.connect_button {
  background-color: #2894c3 !important;
  color: white !important;
  margin-left: -25px;
  margin-top: 20px;
}
.printer_container {
  border: solid 4px #ff671d;
  padding: 15px;
  padding-bottom: 25px;
}
.production_input_label {
  font-weight: bold !important;
  color: black !important;
  font-size: 2.125rem !important;
}
.body-content {
  height: 120vh;
}
.production_header_title {
  background-color: #ff671d;
  color: white;
}
.production_sub_header {
  background-color: #2894c3;
  color: white;
  height: 20px !important;
}
.pass_fail_container {
  border-top: solid .5px;
  border-bottom: solid .5px;
  margin-bottom: 22px;
}
.faBarcode_icon {
  margin-top: 2px;
  margin-right: 5px;
}
.sub_component_page {
  opacity: 100;
  background: #000;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}
.linear_component_page {
  opacity: 100;
  background: rgba(73, 72, 72, 0.871);
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}
.linear_progress_orange {
  background-color: #ff671d !important;
}
.linear_progress_blue {
  background-color: #2894c3 !important;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #ff671d !important;
}
.sub_component_fields {
  margin-bottom: 25px;
}
.production_user_scanned {
  font-size: 355px !important;
  letter-spacing: 0.00001em !important;
  background-color: white !important;
}
.MuiSvgIcon-fontSizeInherit {
  font-size: 45px !important;
}
.production_truck_body {
  width: 100vw !important;
}
#flashGreen {
  font-size: 1050px;
  color: rgb(57, 237, 33);
  z-index: 1;
}
@keyframes glowing {
  0% {
    background-color: green;
  }
  50% {
    background-color: #49e819;
  }
  100% {
    background-color: #49e819;
  }
}
#flashRed {
  font-size: 1050px;
  color: red;
  z-index: 1;
}

@keyframes red-glowing {
  0% {
    background-color: #ff6961;
  }
  50% {
    background-color: #ff6961;
  }
  75% {
    background-color: #ff6961;
  }
}
/* css for microsoft go */
@media only screen and (max-device-height: 1800px) and (max-device-width: 1200px) and (orientation: portrait) {
  .production_user_scanned {
    font-size: 300px !important;
  }
  .body-content {
    height: 120vh !important;
  }
  .production_user_scanned-box {
    margin: 140px 55px !important;
  }
  .production_input_label-box {
    padding-left: 0 !important;
    margin-left: 0 !important;
  }
  .printer_container-grid {
    max-width: 85% !important;
  }
  .connections-grid {
    justify-content: center;
  }
  .connect-header-box {
    margin-left: 75px !important;
  }
  .production_body {
    padding-bottom: 0 !important;
  }
  #flashGreen {
    font-size: 1150px !important;
  }
  .production_user_scanned {
    font-size: 155px !important;
  }
}
@media only screen and (max-device-height: 1200px) and (max-device-width: 1800px) and (orientation: landscape) {
  .body-content {
    height: 120vh !important;
  }
  .production_body {
    padding-bottom: 0 !important;
  }
  #flashGreen {
    font-size: 1200px;
  }
}

/* css for DT Research Rugged Tablet DT370CR */
@media only screen and (max-device-height: 800px) and (max-device-width: 1280px) and (orientation: landscape) {
  .production_body {
    padding-bottom: 0 !important;
  }
  .production_user_scanned {
    font-size: 225px !important;
    letter-spacing: 0.00001em !important;
  }
  .production_user_scanned-box {
    margin: 82px 15px 82px 15px !important;
  }
  .refresh-box {
    margin-top: 15px !important;
  }
  .body-content {
    height: 120vh !important;
  }
  .scan-status-box {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .scan-data-box {
    margin-top: 15px !important;
  }
  .production_header_title {
    font-size: 35px !important;
  }
  #flashGreen {
    font-size: 600px !important;
  }
  .icon-green-check {
    font-size: 8em !important;
  }
  .icon-red-x {
    font-size: 8em !important;
  }
}

@media only screen and (max-device-height: 1280px) and (max-device-width: 800px) and (orientation: portrait) {
  .production_body {
    padding-bottom: 0 !important;
  }
  .production_user_scanned {
    font-size: 225px !important;
    letter-spacing: 0.00001em !important;
  }
  .production_input_label-box {
    padding-right: 30px !important;
  }
  .production_header_title {
    font-size: 35px !important;
  }
  #flashGreen {
    font-size: 600px !important;
  }
  .production_user_scanned-box {
    margin: 115px 15px !important;
  }
  .production_user_scanned {
    font-size: 155px !important;
  }
  .printer_container-grid {
    max-width: 95% !important;
  }
  .printer_container-box {
    margin: 0 !important;
  }
  .icon-green-check {
    font-size: 8em !important;
  }
  .icon-red-x {
    font-size: 8em !important;
  }
  .printer_container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .printer-box {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .refresh-box {
    margin-top: 20px !important;
  }
  .sucess-green-icon {
    font-size: 667px;
    color: rgb(57, 237, 33);
    z-index: 1;
  }
  .fail-red-icon {
    font-size: 667px;
    color: red;
    z-index: 1;
  }
  .barcode_sku_name {
    font-size: 56px;
    font-weight: 700px;
  }
}
