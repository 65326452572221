.MuiFilledInput-root:focus, .serial_number_textfield:focus-within {
  background-color: yellow !important;
}
.batch_scan_input_label {
  /* color: white !important; */
  font-weight: bold !important;
}
.batch_scan_body {
  margin-bottom: -65px !important;
}
.scan {
  margin-top: 80px !important;
}
.total-scanned {
  margin-top: 80px !important;
}
#alert-box {
  font-size: 25px;
}
.MuiAlert-icon {
  flex-wrap: wrap;
  align-content: center;
}