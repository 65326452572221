.App {
    text-align: center;
    background-color: rgb(206, 213, 223);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    width: max-content;
    min-width: 100%;
}


.Card {
    z-index: 1;
    max-width: 360px;
    margin: 0 auto 100px;
    text-align: center;
    background-color: white;
    padding: 32px;
    width: 50%;
    top: 20%;
    left:50%;
    position: absolute;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
}

.paper{
    min-height: 100px;
    min-width: 100px;
    background-color: #5bb7db;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}