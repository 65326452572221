.fishbowl_part-sku-form {
  height: 100vh;
}
.fishbowl_part-sku-form-header-box {
  margin: 15px 15px;
}
.fishbowl_part-sku-form-header {
  color: #163762 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
}
.fishbowl_part-sku-search-box {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 15px 15px;
}
.update-fishbowl_part-sku-search-box {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 15px 15px;
}
.switch-group {
  flex-direction: row !important;
  gap: 1rem !important;
}
.find-fishbowl-part-btn {
  color: white !important;
  background-color: #163762 !important;
}
.find-fishbowl-part-btn:hover {
  background-color: none !important;
}
.core-switch:checked {
  color: #163762 !important;
}
.data-table-header {
  color: #163762;
  font-weight: 500 !important;
  text-align: center;
}
.fishbowl_part-sku-panel_layer-box {
  margin: 25px 15px;
}
.fishbowl_part-sku-core-box {
  margin: 25px 15px;
}
.loading-box {
  text-align: center;
  color: #163762;
}
@media only screen and (max-width: 1460px) {  
  .switch-group {
    flex-wrap: nowrap;
  }
}
@media only screen and (max-width: 1250px) {
  .fishbowl_part-sku-search-box {
    flex-direction: column;
    align-items: center;
  }
  .update-fishbowl_part-sku-search-box {
    flex-direction: column;
    align-items: center;
  }
  .fishbowl_part-sku-form-header-box {
    text-align: center;
  }
  .data-table-header {
    text-align: center;
  }
}