.batch_scan_form {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.scans-modal-header {
  /* border: solid 3px #F84443 !important; */
  /* border-top-right-radius: solid 3px; */
  border-radius: 5px 5px 0 0 ;
}
.scans-modal-title {
  background-color: #F84443;
  color: white;
  padding: 20px;
  text-align: center;
}
.faCheckCircle {
  margin-right: 10px;
  color: green;
}
.faExclamationTriangle {
  margin-right: 10px;
}
.scans-modal-body {
  border: solid 3px #163762 !important;
  border-radius: 0 0 5px 5px ;
}
.scans-modal-results-grid {
  display: flex;
  justify-content: center;
  padding: 15px;
}
.scans-modal-total-scans {
  margin-left: 10px;
}
.total-scans {
  font-size: 40px !important;
  font-weight: 500 !important;
}
.scans-modal-total-successful-scans {
  margin-left: 10px;
  /* font-weight: 900 !important; */
}
.successful-scans {
  font-size: 40px !important;
  font-weight: 500 !important;
}
.scans-modal-invalid-scans-grid {
  height: 10px;
}