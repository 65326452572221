.user-container{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.submit-user-btn{
    margin-top: 20px;
}

.user-form-select{
    margin-top: 10px !important;
    margin-left: 20px !important;
    margin-bottom: 15px !important;
    min-width: 195px !important;
}

.user-pwd-switch{
    margin-left: 50px !important;
    margin-top: 20px !important;
}