.delete-modal-title{
  background-color: #f00e0e !important;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 75px;
}
.delete-modal-typo-title{
  text-align: center;
}
.delete-modal-description{
  margin: 15px;
  text-align: center;
  margin-top: 30px;
}
.delete-modal-body-description{
  font-weight: bold !important;
  font-size: 18px !important;
  margin: 15px !important;
}
.delete-modal-buttonBox {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 15px;
}
.delete-modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: auto;
  box-shadow: 23px;
  padding: 4px;
  outline: none !important;
}
.delete-modal-card {
  overflow: auto;
  height: auto;
  width: 500px;
  padding-bottom: 25px;
}