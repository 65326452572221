body {
  padding: 0 !important;
  overflow: visible !important;
}
.core-update-form {
  height: 100vh;
  padding: 1rem;
  
}
.core-info-input {
  min-width: 120px !important;
  margin-right: 15px !important;
  width: 200px !important;
  margin-left: 20px !important;
}
.core-warning {
  color: red;
  margin: 15px 0 !important;
  font-weight: bold !important;
}
.new-layer-btn {
  display: block !important;
  margin-left: 20px !important;
  margin: 20px !important;
  margin-bottom: 30px !important;
}
.update-box {
  margin-top: 150px !important;
}
.update-btn {
  background: rgb(30, 66, 113) !important;
  color: white !important;
}

@media only screen and (max-width: 1000px) {
  .parent-core-info-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .update-heading-box {
    text-align: center;
  }
  .update-box {
    text-align: center;
  }
}